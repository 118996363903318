import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { SingleDraft } from '@app/store/models';
import { singleDraftsActions } from '../actions/single-drafts.actions';
import { selectDraftsList, selectLoaded } from '../selectors/single-drafts.selector';

@Injectable({
    providedIn: 'root',
})
export class SingleDraftsFacade {
    private readonly store = inject(Store);

    draftsList$ = this.store.select(selectDraftsList);
    draftsListNonNullable$ = this.store.select(selectDraftsList).pipe(filter((list): list is SingleDraft[] => list !== null));
    loaded$ = this.store.select(selectLoaded);

    getDraftsList(): void {
        this.store.dispatch(singleDraftsActions.getDraftsList());
    }

    clear(): void {
        this.store.dispatch(singleDraftsActions.clear());
    }
}
