import { SingleDraftItemDto } from './single-draft-item.dto';

export class SingleDraftItem {
    id: string;
    name: string;
    description: string;
    publishDate: Date | null;
    coverFileId: string | null;
    artists: string;

    constructor(dto: SingleDraftItemDto) {
        this.id = dto.id;
        this.artists = dto.artists?.join(', ') ?? '';
        this.name = dto.name ?? '';
        this.description = dto.description ?? '';
        this.publishDate = dto.publishDate ? new Date(Math.round(dto.publishDate * 1000)) : null;
        this.coverFileId = dto.coverFileId ?? null;
    }
}
