import { createReducer, on } from '@ngrx/store';
import { singleDraftsActions } from '../actions/single-drafts.actions';
import { SingleDraftItem } from '../models';

export interface SingleDraftsState {
    draftsList: SingleDraftItem[] | null;
    loaded: null | boolean;
}

export const initialState: SingleDraftsState = {
    draftsList: null,
    loaded: null,
};

export const singleDraftsReducer = createReducer(
    initialState,
    on(singleDraftsActions.getDraftsList, (state): SingleDraftsState => ({ ...state, loaded: null })),
    on(singleDraftsActions.getDraftsListSuccess, (state, { value }): SingleDraftsState => ({ ...state, loaded: true, draftsList: value })),
    on(singleDraftsActions.getDraftsListError, (state): SingleDraftsState => ({ ...state, loaded: false })),

    on(singleDraftsActions.clear, (): SingleDraftsState => ({ ...initialState })),
);
