import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, finalize, map, of } from 'rxjs';
import { LoadersFacade } from '@app/store/loader';
import { singleDraftsActions } from '../actions/single-drafts.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class SingleDraftsEffects {
    private readonly actions$ = inject(Actions);
    private readonly loadersFacade = inject(LoadersFacade);
    private readonly httpService = inject(HttpService);

    getDraftsList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(singleDraftsActions.getDraftsList),
            exhaustMap(() => {
                this.loadersFacade.add('getDraftsList');

                return this.httpService.getDraftsList().pipe(
                    map((value) => singleDraftsActions.getDraftsListSuccess({ value })),
                    catchError(() => of(singleDraftsActions.getDraftsListError())),
                    finalize(() => this.loadersFacade.delete('getDraftsList')),
                );
            }),
        );
    });
}
