import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { SingleDraftsFacade } from '../facades/single-drafts.facade';

export const singleDraftsCleanup: CanDeactivateFn<unknown> = () => {
    const singleDraftsFacade = inject(SingleDraftsFacade);

    singleDraftsFacade.clear();
    return true;
};
