import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ApiResponse } from '@app/shared/models';
import { SingleDraftItem, SingleDraftItemDto } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getDraftsList() {
        return this.httpClient
            .get<ApiResponse<SingleDraftItemDto[]>>('api/v1/draft/single')
            .pipe(map((res) => res.data.map((dto) => new SingleDraftItem(dto))));
    }
}
