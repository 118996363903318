import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SingleDraftItem } from '../models';

export const singleDraftsActions = createActionGroup({
    source: 'Single Drafts',
    events: {
        'Get Drafts List': emptyProps(),
        'Get Drafts List Success': props<{ value: SingleDraftItem[] }>(),
        'Get Drafts List Error': emptyProps(),

        Clear: emptyProps(),
    },
});
