import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SINGLE_DRAFTS_FEATURE_KEY } from './keys';
import { singleDraftsReducer } from './reducers/single-drafts.reducer';
import { SingleDraftsEffects } from './effects/single-drafts.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(SINGLE_DRAFTS_FEATURE_KEY, singleDraftsReducer), EffectsModule.forFeature([SingleDraftsEffects])],
})
export class SingleDraftsStoreModule {}
